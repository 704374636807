import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const Slider = () => {
  return (
    <Splide options={{
      type: 'slide', // Change to 'carousel' for a carousel-style slider
      perPage: 2,
      perMove: 1, // Number of slides to show per page
      arrows: true, // Toggle navigation arrows
      pagination: false,
      autoplay: true,
      loop: true, // Toggle pagination dots
      breakpoints: {
        640: {
          perPage: 2,
        },
        480: {
          perPage: 1,
        },
      },
    }}>
      <SplideSlide>
        <div>
          <div className="box">
            <p>
              Outstanding service! Duodots transformed our online presence, increasing our visibility and driving more traffic to our website. Highly recommended!
            </p>
          </div>

          <b>Rahul Sharma</b>
          <p>Business Owner</p>
        </div>
      </SplideSlide>
      <SplideSlide>
        <div>
          <div className="box">
            <p>
              Duodots exceeded our expectations with their innovative designs and strategic approach. They truly understand our brand and delivered exceptional results.
            </p>
          </div>

          <b>Priya Singh</b>
          <p>Marketing Manager</p>
        </div>
      </SplideSlide>
      <SplideSlide>
        <div>
          <div className="box">
            <p>
              Working with Duodots was a game-changer for our business. Their expertise and professionalism helped us stand out in a crowded market. Thank you!
            </p>
          </div>

          <b>Ankit Patel</b>
          <p>Entrepreneur</p>
        </div>
      </SplideSlide>
      <SplideSlide>
        <div>
          <div className="box">
            <p>
              Choosing Duodots was one of the best decisions we made for our company. Their creative designs and strategic insights have significantly boosted our online presence.
            </p>
          </div>

          <b>Rajesh Verma</b>
          <p>Verma enterprises</p>
        </div>
      </SplideSlide>

      <SplideSlide>
        <div>
          <div className="box">
            <p>
              Thanks to Duodots, our website now stands out in a competitive market. Their attention to detail and commitment to excellence are truly impressive.
            </p>
          </div>

          <b>Sanjay Kumar</b>
          <p>Digital Marketing Manager</p>
        </div>
      </SplideSlide>

      <SplideSlide>
        <div>
          <div className="box">
            <p>
              Working with Duodots was a breeze! They understood our requirements perfectly and delivered a website that exceeded our expectations. Highly recommend their services!
            </p>
          </div>

          <b>Vikram Singh</b>
          <p>Startup Founder</p>
        </div>
      </SplideSlide>
      {/* Add more SplideSlides as needed */}
    </Splide>
  );
};

export default Slider;
