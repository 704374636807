import React, { useRef, useEffect, useState } from 'react';
import heroimg from '../../images/hero/graphic-hero.gif';
import whatsapp from '../../images/icon/whatsapp.png';
import emailIcon from '../../images/icon/email.png';
import premium from '../../images/icon/social-media.png';
import analysis from '../../images/icon/analysis.png';
import label from '../../images/icon/label.png';
import logoDesign from '../../images/icon/logo-design.png';
import social from '../../images/icon/social-media.png';
import video from '../../images/icon/video.png';
import Typed from 'typed.js';
import { Helmet } from 'react-helmet';
import $ from 'jquery';

const Home = () => {

  const schema = {
    "@context": "https://schema.org",
    "@type": "Service",
    "serviceType": "Graphic Designing Service",
    "provider": {
      "@type": "Organization",
      "name": "Duodots",
      "url": "https://duodots.in/graphic-design"
    },
    "areaServed": {
      "@type": "Country",
      "name": "India"
    },
    "description": "At Duodots, we craft visual experiences that leave a lasting impression. Our team of talented designers specializes in delivering bespoke solutions tailored to your unique needs. From logo design to branding, web graphics to print materials, we offer a comprehensive range of services to elevate your brand presence across all platforms. With a keen eye for detail and a passion for innovation, we bring your ideas to life with stunning visuals that resonate with your audience. Whether you're a startup seeking a memorable identity or an established business aiming to refresh your image, Duodots is here to turn your vision into reality. Experience the power of compelling design. Partner with Duodots for your next project and let your brand shine like never before."
  }

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [service, setService] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === 'email') {
          setEmail(value);
      } else if (name === 'name') {
          setName(value);
      } else if (name === 'phone') {
        setPhone(value);
    }
    else if (name === 'service') {
      setService(value);
  }  else if (name === 'message') {
          setMessage(value);
      }
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      setIsLoading(true);
      const form = $(e.target);
      $.ajax({
          type: "POST",
          url: form.attr("action"),
          data: {
              email: email,
              name: name,
              phone: phone,
              service: service,
              message: message,
          },
          success(data) {
              if (data == 200) {
                  setResult(<div className="success-msg">
                      <i className="fi fi-sr-info"></i>
                      <p>Your message has been sent.</p>
                  </div>);
                  setIsLoading(false);
                  setEmail(""); // Clear email input
                  setName(""); // Clear name input
                  setPhone(""); // Clear name input
                  setService(""); // Clear name input
                  setMessage(""); // Clear message input
              } else {
                  setResult(<div className="success-msg error">
                      <i className="fi fi-sr-cross-circle"></i>
                      <p>Oops an error occurred.</p>
                  </div>);
                  setIsLoading(false);
              }
          },
      });
  };


  const HeroHeading = () => {
    const el = useRef(null);

    useEffect(() => {
      const typed = new Typed(el.current, {
        strings: ["Logo Designing", "Graphic Designing", "Brochure Designing", "UI/UX Designing"],
        loop: true,
        typeSpeed: 50,
      });

      return () => typed.destroy();
    }, []);

    return <span ref={el}></span>;
  };





  return (
    <>

      <Helmet>
        <meta name="theme-color" content="#FF801F" />
        <link rel="canonical" href="https://www.duodots.in/graphic-design" />
        <title>Best Graphic Designing Service Agency In India - Duodots</title>
        <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
      </Helmet>

      <div className="hero graphic-design">
        <div className="hero_content">
          <h4>Duodots – India's Best Graphic Design Agency</h4>
          <h1>
            India's Best <br />
            <HeroHeading /><br />
            Service Provider.
          </h1>
          <p>
            From Concept to Creation: Your Go-To Graphic Design Agency
          </p>
          <a href="#contactForm">Get Started</a>

        </div>
        <div className="hero_image">
          <img src={heroimg} />
        </div>
      </div>

      <div className="section-padding">
        <div className="section_title">
          <h1>Duodots: Your Creative Partner for Exceptional Graphic Design Services</h1>
          <p>
            At Duodots, we craft visual experiences that leave a lasting impression. Our team of talented designers specializes in delivering bespoke solutions tailored to your unique needs.
            From logo design to branding, web graphics to print materials, we offer a comprehensive range of services to elevate your brand presence across all platforms.

            With a keen eye for detail and a passion for innovation, we bring your ideas to life with stunning visuals that resonate with your audience.

            Whether you're a startup seeking a memorable identity or an established business aiming to refresh your image, Duodots is here to turn your vision into reality.

            Experience the power of compelling design. Partner with Duodots for your next project and let your brand shine like never before.
          </p>
        </div>

        <div className="home-features">
          <div className="feature" style={{ '--color': '37, 133, 207' }}>
            <i className="fi fi-sr-lightbulb-on"></i>
            <h1>Innovative Concepts</h1>
            <p>
              Fresh and inventive ideas to set your brand apart from the competition.
            </p>
          </div>

          <div className="feature" style={{ '--color': '241, 78, 28' }}>
            <i className="fi fi-sr-crown"></i>
            <h1>Seamless Branding</h1>
            <p>
              Consistent design elements across all platforms for a cohesive brand image.
            </p>
          </div>

          <div className="feature" style={{ '--color': '254, 154, 0' }}>
            <i className="fi fi-sr-time-forward"></i>
            <h1>Timely Delivery</h1>
            <p>
              Efficient turnaround times without compromising on quality.
            </p>
          </div>
        </div>


        <div className="section_title">
          <h1>Graphic Design Services</h1>
          <p>
            Duodots is a leading Graphic Design Company in India. Our creative team specialise in graphic design services & digital marketing solutions that can help your business thrive. Our services include Graphic Design Services, Digital Illustration Services, Web Design and Development, Explainer Video Services and Digital Marketing Services.
          </p>
        </div>

        <div className="service_row">

          <div className="service_box">
            <img src={premium} />
            <h4>Branding & Identity</h4>
            <p>
              Crafting cohesive brand experiences, conveying your unique essence through visual elements and strategic messaging
            </p>
          </div>

          <div className="service_box">
            <img src={analysis} />
            <h4>Infographics</h4>
            <p>
              Flourish your dense data and make it appealing with our stunning infographics design.
            </p>
          </div>

          <div className="service_box">
            <img src={video} />
            <h4>Explainer Videos</h4>
            <p>
              Animated visuals simplifying complex concepts, engaging and educating your audience effectively in seconds.
            </p>
          </div>

          <div className="service_box">
            <img src={social} />
            <h4>Social Media Post</h4>
            <p>
              Crafted visuals for impactful social media presence, fostering meaningful engagement across platforms.
            </p>
          </div>

          <div className="service_box">
            <img src={logoDesign} />
            <h4>Logo Designing</h4>
            <p>
              Iconic brand symbols, epitomizing identity and essence in memorable visuals for instant recognition
            </p>
          </div>

          <div className="service_box">
            <img src={label} />
            <h4>Product Label Design</h4>
            <p>
              Iconic brand symbols, epitomizing identity and essence in memorable visuals for instant recognition
            </p>
          </div>




        </div>







      </div>

      <div className="contact graphic-design" id='contactForm'>
        <div className="section_title">
          <h1>
            The Only Graphic Designing Company You Need – Contact Us for Expert
            Solutions
          </h1>
          <p>Tell us your branding needs, and we’ll bring your vision to life.</p>
        </div>

        <div className="contact_row">
          <div className="contact_details">
            <p>
              If you have any questions, inquiries, or require assistance, please
              don’t hesitate to reach out.
            </p>

            <p>Benefits of Choosing Our Graphic Designing Service:</p>

            <ul>
              <li>Professional Design</li>
              <li>High-Resolution Graphics</li>
              <li>Scalablity</li>
              <li>Reliable Support</li>
            </ul>

            <p>Contact us:</p>

            <div className="contact_div">
              <img src={whatsapp} />
              <div>
                <h4>Whatsapp:</h4>
                <a href="https://wa.me/917042283396" target='_blank'>+91 7042283396</a>
              </div>
            </div>
            <div className="contact_div">
              <img src={emailIcon} />
              <div>
                <h4>Mail us:</h4>
                <a href="mailto:duodots.web@gmail.com">duodots.web@gmail.com</a>
              </div>
            </div>
          </div>

          <form
            action="https://duodots.in/service-email.php"
            method="post"
            className="contact_form"
            onSubmit={(event) => handleSubmit(event)}
          >
            <label for="name">Your Full Name</label>
            <input type="text" name="name" id="name"  value={name} onChange={(event) =>handleChange(event)} required />

            <label for="email">Email Address</label>
            <input type="email" name="email" id="email"  value={email} onChange={(event) =>handleChange(event)} required />

            <label for="phone">Phone Number</label>
            <input type="tel" name="phone" id="phone"  value={phone} onChange={(event) =>handleChange(event)} required />

            <label for="service">Choose Service :</label>
            <select name="service" id="service"  value={service} onChange={(event) =>handleChange(event)}>
              <option value="#" selected disabled>Choose</option>
              <option value="Logo Design">Logo Design</option>
              <option value="Brochure / Catalogue Design">Brochure / Catalogue Design</option>
              <option value="Poster Design">Poster Design</option>
              <option value="Product Label Design">Product Label Design</option>
              <option value="Other Service">Other Service</option>
            </select>

            <label for="message">Message</label>
            <textarea
              name="message"
              id="message"
              cols="30"
              rows="5"
              value={message} 
              onChange={(event) =>handleChange(event)}
              placeholder="Tell us about your service needs so we can assist you effectively."
            ></textarea>

                        
<button type="submit" disabled={isLoading}>
                                {isLoading ? 'Sending...' : 'Submit'}
                            </button>
                        {result}

          </form>
        </div>
      </div>
    </>
  )
}

export default Home
