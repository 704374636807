import React, { useRef, useEffect, useState } from 'react';
import heroimg from '../../images/hero/main-hero.gif';
import wp from '../../images/software-icon/wordpress.png';
import woocommerce from '../../images/software-icon/woo-commerce.png';
import html from '../../images/software-icon/html.png';
import shopify from '../../images/software-icon/shopify.png';
import corel from '../../images/software-icon/coreldraw.png';
import photoshop from '../../images/software-icon/photoshop.png';
import figma from '../../images/software-icon/figma.png';
import illustrator from '../../images/software-icon/illustrator.png';
import websiteDesign from '../../images/service/website-design.jpg';
import branding from '../../images/service/graphic-design.png';
import whatsapp from '../../images/icon/whatsapp.png';
import emailIcon from '../../images/icon/email.png';
import Typed from 'typed.js';
import Slider from '../Testimonial';
import { Helmet } from 'react-helmet';
import mainlogo from '../../images/logo.png';
import logoWebsite from '../../images/duodots-website.png';
import logoGraphic from '../../images/duodots-graphic.png';
import $ from "jquery";
import { Link } from 'react-router-dom';


const Home = () => {

  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Duodots",
    "url": "https://duodots.in/",
    "logo": "https://duodots.in/static/media/logo.fc5f5c0af74e2ff592d9.png",
    "description": "Duodots emerges as a beacon of creativity and expertise in Delhi's website and graphic design domain. We're not just a service provider; we're partners in your digital success story. With a relentless pursuit of perfection, we craft websites and graphics that not only dazzle the eyes but also resonate with your audience. Trust Duodots to elevate your online presence and leave a lasting impression.",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+91-982-157-4580",
      "telephone2": "+91-704-228-3396",
      "contactType": "Contact with us"
    },
    // "sameAs": [
    //   "https://www.facebook.com/yourcompany",
    //   "https://twitter.com/yourcompany",
    //   "https://www.linkedin.com/company/yourcompany"
    // ]
  }

  const [currentPage, setCurrentPage] = useState('home');

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getLogoAndColor = () => {
    let logo, color;

    if (currentPage === 'home') {
      logo = mainlogo;
      color = '#6F15BB'; // Example hex color for home
    } else if (currentPage === 'graphic') {
      logo = logoGraphic;
      color = '#FF801F'; // Example hex color for about
    } else if (currentPage === 'website') {
      logo = logoWebsite;
      color = '#3CAA9F'; // Example hex color for contact
    }

    return { logo, color };
  };

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === 'email') {
          setEmail(value);
      } else if (name === 'name') {
          setName(value);
      } else if (name === 'phone') {
        setPhone(value);
    }
    else if (name === 'subject') {
      setSubject(value);
  }  else if (name === 'message') {
          setMessage(value);
      }
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      setIsLoading(true);
      const form = $(e.target);
      $.ajax({
          type: "POST",
          url: form.attr("action"),
          data: {
              email: email,
              name: name,
              phone: phone,
              subject: subject,
              message: message,
          },
          success(data) {
              if (data == 200) {
                  setResult(<div className="success-msg">
                      <i className="fi fi-sr-info"></i>
                      <p>Your message has been sent.</p>
                  </div>);
                  setIsLoading(false);
                  setEmail(""); // Clear email input
                  setName(""); // Clear name input
                  setPhone(""); // Clear name input
                  setSubject(""); // Clear name input
                  setMessage(""); // Clear message input
              } else {
                  setResult(<div className="success-msg error">
                      <i className="fi fi-sr-cross-circle"></i>
                      <p>Oops an error occurred.</p>
                  </div>);
                  setIsLoading(false);
              }
          },
      });
  };
  const HeroHeading = () => {
    const el = useRef(null);

    useEffect(() => {
      const typed = new Typed(el.current, {
        strings: ['Website Designing', 'Graphic Designing'],
        loop: true,
        typeSpeed: 50,
      });

      return () => typed.destroy();
    }, []);

    return <span ref={el}></span>;
  };






  return (
    <>

      <Helmet>
        <link rel="canonical" href="https://www.duodots.in/contact" />
        <title> Duodots – Your Digital Success Starts Here</title>
        <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
      </Helmet>

      <div className="hero">
        <div className="hero_content">
          <h4>Duodots – Your Digital Success Starts Here</h4>
          <h1>
            Trusted <br />
            <HeroHeading /><br />
            Company in Delhi NCR!
          </h1>
          <p>
            Crafting digital excellence with trusted website and graphic design services in Delhi NCR.
          </p>
          <a href="#contact">Get Started</a>
          <div className="review">
            <div className="stars">
              <i className="fi fi-sr-star"></i>
              <i className="fi fi-sr-star"></i>
              <i className="fi fi-sr-star"></i>
              <i className="fi fi-sr-star"></i>
              <i className="fi fi-sr-star"></i>
            </div>
            <p>Based on 500+ happy client reviews</p>
          </div>
        </div>
        <div className="hero_image">
          <img src={heroimg} />
        </div>
      </div>

      <div className="softwares">
        <img src={wp} alt="" />
        <img src={woocommerce} alt="" />
        <img src={html} alt="" />
        <img src={shopify} alt="" />
        <img src={corel} alt="" />
        <img src={photoshop} alt="" />
        <img src={figma} alt="" />
        <img src={illustrator} alt="" />
      </div>

      <div className="section-padding">
        <div className="section_title">
          <h1>The Importance of   <HeroHeading /></h1>
          <p>
            Duodots emerges as a beacon of creativity and expertise in Delhi's website and graphic design domain. We're not just a service provider; we're partners in your digital success story. With a relentless pursuit of perfection, we craft websites and graphics that not only dazzle the eyes but also resonate with your audience. Trust Duodots to elevate your online presence and leave a lasting impression.
          </p>
        </div>

        <div className="home-features">
          <div className="feature" style={{ '--color': '37, 133, 207' }}>
            <i className="fi fi-sr-shopping-cart"></i>
            <h1>Sell Anything Online</h1>
            <p>
              We develop website that enable you to efficiently sell a wide range
              of products or services online.
            </p>
          </div>

          <div className="feature" style={{ '--color': '241, 78, 28' }}>
            <i className="fi fi-sr-chart-histogram"></i>
            <h1>Boost online sales</h1>
            <p>
              We carefully research and plan before creating your website, which
              lead to a large amount of boost in your sales.
            </p>
          </div>

          <div className="feature" style={{ '--color': '254, 154, 0' }}>
            <i className="fi fi-sr-shield-check"></i>
            <h1>Credibility and Trust</h1>
            <p>
              Our websites enhance online credibility, gain trust, and give you a
              competitive edge over your competition.
            </p>
          </div>
        </div>

        <div className="service_section">
          <div className="service_content">
            <h1>Take Your Business Digital: Duodots Website Design Service</h1>
            <ul className="service_list">
              <li>
                <i className="fi fi-sr-map-marker-check"></i>
                <span>
                  Tailored website designs to reflect your brand identity and resonate with your target audience.
                </span>
              </li>
              <li>
                <i className="fi fi-sr-map-marker-check"></i>
                <span>
                  Mobile-friendly websites that adapt seamlessly to various screen sizes and devices.
                </span>
              </li>
              <li>
                <i className="fi fi-sr-map-marker-check"></i>
                <span>
                  Built-in search engine optimization strategies to improve visibility and enhance your website's ranking on search engines.
                </span>
              </li>
              <li>
                <i className="fi fi-sr-map-marker-check"></i>
                <span>
                  Optimized performance for swift loading times, reducing bounce rates and improving user satisfaction.
                </span>
              </li>
              <li>
                <i className="fi fi-sr-map-marker-check"></i>
                <span>
                  Implementation of robust security measures to protect your website and user data from cyber threats.
                </span>
              </li>
            </ul>

            <Link to="/website-design" onClick={() => handlePageChange('website')} className="btn">Know More</Link>
          </div>
          <div className="service_image">

            <img src={websiteDesign} />
          </div>
        </div>

        <div className="service_section">
          <div className="service_image start">
            <img src={branding} />
          </div>

          <div className="service_content">
            <h1>Innovative Design Solutions for Your Brand's Unique Vision</h1>
            <ul className="service_list">
              <li>
                <i className="fi fi-sr-map-marker-check"></i>
                <span>
                  Tailored Designs: Customized graphic designs tailored to your brand's unique identity and vision.
                </span>
              </li>
              <li>
                <i className="fi fi-sr-map-marker-check"></i>
                <span>
                  Creative Excellence: Unlocking creativity to craft visually stunning and impactful designs that captivate your audience.
                </span>
              </li>
              <li>
                <i className="fi fi-sr-map-marker-check"></i>
                <span>
                  Brand Enhancement: Elevating your brand's visual identity through strategic and innovative graphic design solutions.
                </span>
              </li>
              <li>
                <i className="fi fi-sr-map-marker-check"></i>
                <span>
                  From logos to marketing materials, offering a comprehensive range of graphic design services to meet your needs.
                </span>
              </li>
              <li>
                <i className="fi fi-sr-map-marker-check"></i>
                <span>
                  Professional Quality: Delivering professional-grade designs that reflect the professionalism and quality of your brand.
                </span>
              </li>
              <Link to="/graphic-design" className="btn">Know More</Link>
            </ul>
          </div>
        </div>

        <div className="section_title">
          <h2>
            <span>Contact Us:</span> Let’s Build Something Extraordinary Together!
          </h2>
          <p>
            Ready to take your online presence to the next level? Contact Duodots today!
          </p>

          <a href="/contact" className="btn"> Contact us </a>
        </div>

        <div className="service_section">
          <div className="service_content">
            <h1>
              Why Choose Us As Your <br />
              <span>Digital Branding</span> Partner
            </h1>
          </div>

          <div className="service_content">
            <p>
              With years of industry experience, we offer creative and tailored solutions to elevate your brand's digital presence. Our track record of success speaks for itself, showcasing our ability to deliver measurable results. Through collaborative partnership, we work closely with you to bring your vision to life effectively and drive your brand's success in the digital landscape.
            </p>
          </div>
        </div>

        <div className="whyus">
          <div className="why_box">
            <i className="fi fi-sr-workflow-setting-alt"></i>
            <h1>Clear and transparent process</h1>
            <p>
              Our process ensures clarity and transparency, fostering trust and
              understanding. From start to finish, we prioritize openness,
              communication, and accountability for a seamless experience.
            </p>
          </div>

          <div className="why_box">
            <i className="fi fi-sr-shield-check"></i>
            <h1>Robust website security implementation.</h1>
            <p>
              We employ robust website security measures, safeguarding against
              various threats. Our implementation ensures comprehensive
              protection, preserving data integrity and user confidence.
            </p>
          </div>

          <div className="why_box">
            <i className="fi fi-sr-layout-fluid"></i>
            <h1>Premium Design & Typography</h1>
            <p>
              Our designs exemplify premium quality, meticulously crafted with
              exquisite typography. Elevate your brand with sophisticated visuals
              and refined typographic elements that captivate and resonate with
              your audience.
            </p>
          </div>

          <div className="why_box">
            <i className="fi fi-sr-devices"></i>
            <h1>Responsive design user experience across devices.</h1>
            <p>
              Our responsive design guarantees an optimal user experience on all
              devices. Seamlessly adapt to various screen sizes, enhancing
              accessibility and engagement for every visitor, irrespective of
              their device choice.
            </p>
          </div>

          <div className="why_box">
            <i className="fi fi-sr-graphic-style"></i>
            <h1>Visual premium graphics</h1>
            <p>
              Experience visual excellence with our premium graphics. Elevate your
              brand with stunning visuals crafted to captivate and inspire,
              leaving a lasting impression on your audience.
            </p>
          </div>

          <div className="why_box">
            <i className="fi fi-sr-pen-clip"></i>
            <h1>Fully customizable</h1>
            <p>
              Enjoy complete control with our fully customizable solutions. Tailor
              every aspect to suit your unique needs, empowering you to achieve
              your vision with flexibility and precision.
            </p>
          </div>
        </div>
      </div>

      <div className="section-padding">
        <div className="section_title">
          <h1>Discover What Our Clients Are Saying About Us</h1>
        </div>

        <div className="testimonial">
          <Slider />
        </div>



      </div>

      <div className="contact" id='contact'>
        <div className="section_title">
          <h1>
            The Only Web Development Company You Need – Contact Us for Expert
            Solutions
          </h1>
          <p>Tell us your website needs, and we’ll bring your vision to life.</p>
        </div>

        <div className="contact_row">
          <div className="contact_details">
            <p>
              If you have any questions, inquiries, or require assistance, please
              don’t hesitate to reach out.
            </p>

            <p>Benefits of Choosing Our Digital Services:</p>

            <ul>
              <li>Professional Website</li>
              <li>Graphic Design Service</li>
              <li>Scalablity</li>
              <li>Reliable Support</li>
            </ul>

            <p>Contact us:</p>

            <div className="contact_div">
              <img src={whatsapp} />
              <div>
                <h4>Whatsapp:</h4>
                <a href="https://wa.me/919821574580" target='_blank'>+91 9821574580</a> <br />
                <a href="https://wa.me/917042283396" target='_blank'>+91 7042283396</a>
              </div>
            </div>
            <div className="contact_div">
              <img src={emailIcon} />
              <div>
                <h4>Mail us:</h4>
                <a href="mailto:duodots.web@gmail.com">duodots.web@gmail.com</a>
              </div>
            </div>

          </div>

          <form
            action="https://duodots.in/contact-email.php"
            method="post"
            className="contact_form"
            onSubmit={(event) => handleSubmit(event)}
          >

            <label htmlFor="name">Full Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(event) =>
                handleChange(event)
              }
            />

            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(event) =>
                handleChange(event)
              }
            />

            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={phone}
              onChange={(event) =>
                handleChange(event)
              }
            />

<label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={subject}
              onChange={(event) =>
                handleChange(event)
              }
            />


          

            <label for="msg">Message</label>
            <textarea
              name="message"
              id="message"
              cols="30"
              rows="5"
              placeholder="Tell us about your service needs so we can assist you effectively."
              value={message}
              onChange={handleChange}
            ></textarea>

<button type="submit" disabled={isLoading}>
                                {isLoading ? 'Sending...' : 'Submit'}
                            </button>
                        {result}

          </form>
        </div>
      </div>
    </>
  )
}

export default Home