import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import mainlogo from '../images/logo.png';
import logoWebsite from '../images/duodots-website.png';
import logoGraphic from '../images/duodots-graphic.png';


const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0);
      };
    

    return (

        <>
            <footer>

                <div className="footer_col">
                    <p>
                        Duodots your trusted partner in bringing small businesses online. We specialize in building professional websites that empower entrepreneurs and small business owners to establish a strong digital presence.
                    </p>
                </div>

                <div className="footer_menu">
                    <h2>Company</h2>
                    <ul>
                        <li><Link to="/website-design" onClick={scrollToTop}>Website Design</Link></li>
                        <li><Link to="/graphic-design" onClick={scrollToTop}>Graphic Design</Link></li>

                        <li><Link to="/terms-and-conditions" onClick={scrollToTop}>Term & Conditions</Link></li>
                    </ul>
                </div> 

                <div className="footer_menu">
                    <h2>Support</h2>
                    <ul>
                        <li><Link to="/faq" onClick={scrollToTop}>Faqs </Link></li>
                        <li><Link to="/contact" onClick={scrollToTop}>Contact</Link></li>
                        <li><a href="mailto:duodots.web@gmail.com">duodots.web@gmail.com</a></li>
                    </ul>
                </div>
                <div className="footer_menu">
                    <h2>Contact</h2>
                    <ul>
                        <li><a href="tel:+91-9821574580">+91 98215 74580 </a></li>
                        <li><a href="tel:+91-7042283396">+91 70422 83396 </a></li>
                        <li><Link to="/contact" onClick={scrollToTop}>Send Inquiry</Link></li>
                    </ul>
                </div>

            </footer>

            <div className="copyright">
                ©  2024. All Rights Reserved <span>Duodots.</span>
            </div>
        </>

    )
}

export default Footer
