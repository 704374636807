import React from 'react'
import erroimg from '../../images/404.png';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';


const ErrorPage = () => {
  return (
    <>
      <Helmet>
        <title>Contact - Duoodots</title>
      </Helmet>

      <div className="section_padding error-page">

        <img src={erroimg} width="400px" /> 

        <div className="section_title">
          <h1>Look like you're lost !</h1>
          <Link to="/" className='btn'>Go Back</Link>
        </div>

      </div>

    </>
  )
}

export default ErrorPage
