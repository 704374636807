import './assets/css/style.min.css';
import './assets/css/responsive.min.css';
import Header from './component/Header';
import Footer from './component/Footer';
import Home from './component/page/Home';
import Faq from './component/page/Faq';
import Contact from './component/page/Contact';
import Graphic from './component/page/Graphic';
import Website from './component/page/Website';
import ErrorPage from './component/page/ErrorPage';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Terms from './component/page/Terms';
import Form from './component/page/Form';


const ErrorPages = () => {
  const navigate = useNavigate();

  // Optional: Add a button to redirect to the home page
  const handleRedirectHome = () => {
    navigate('/');
  };

  return (
    <ErrorPage />
  );
};



function App() {


  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="faq" element={<Faq />} />
        <Route path="contact" element={<Contact />} />
        <Route path="graphic-design" element={<Graphic />} />
        <Route path="website-design" element={<Website />} />
        <Route path="terms-and-conditions" element={<Terms />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
