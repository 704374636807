import React, { useRef, useEffect, useState } from 'react';
import heroimg from '../../images/hero/website-hero.gif';
import whatsapp from '../../images/icon/whatsapp.png';
import emailIcon from '../../images/icon/email.png';
import responsive from '../../images/icon/responsive.png';
import ecom from '../../images/icon/online-store.png';
import wp from '../../images/icon/wordpress-logo.png';
import seo from '../../images/icon/data-research.png';
import hosting from '../../images/icon/cloud.png';
import cms from '../../images/icon/cms.png';
import Typed from 'typed.js';
import { Helmet } from 'react-helmet';
import $ from 'jquery';


const Website = () => {

  const schem = {
    "@context": "https://schema.org",
    "@type": "Service",
    "serviceType": "Website Designing Service",
    "provider": {
      "@type": "Organization",
      "name": "Duodots",
      "url": "https://duodots.in/website-design"
    },
    "areaServed": {
      "@type": "Country",
      "name": "India"
    },
    "description": "Duodots, we specialize in empowering businesses with captivating and functional online platforms. Our professional website design services are tailored to amplify your brand's digital presence, ensuring it stands out in the competitive online landscape."
  }

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [service, setService] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === 'email') {
          setEmail(value);
      } else if (name === 'name') {
          setName(value);
      } else if (name === 'phone') {
        setPhone(value);
    }
    else if (name === 'service') {
      setService(value);
  }  else if (name === 'message') {
          setMessage(value);
      }
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      setIsLoading(true);
      const form = $(e.target);
      $.ajax({
          type: "POST",
          url: form.attr("action"),
          data: {
              email: email,
              name: name,
              phone: phone,
              service: service,
              message: message,
          },
          success(data) {
              if (data == 200) {
                  setResult(<div className="success-msg">
                      <i className="fi fi-sr-info"></i>
                      <p>Your message has been sent.</p>
                  </div>);
                  setIsLoading(false);
                  setEmail(""); // Clear email input
                  setName(""); // Clear name input
                  setPhone(""); // Clear name input
                  setService(""); // Clear name input
                  setMessage(""); // Clear message input
              } else {
                  setResult(<div className="success-msg error">
                      <i className="fi fi-sr-cross-circle"></i>
                      <p>Oops an error occurred.</p>
                  </div>);
                  setIsLoading(false);
              }
          },
      });
  };

  const HeroHeading = () => {
    const el = useRef(null);

    useEffect(() => {
      const typed = new Typed(el.current, {
        strings: ["Website Designing", "Wordpress Development", "Ecommerce"],
        loop: true,
        typeSpeed: 50,
      });

      return () => typed.destroy();
    }, []);

    return <span ref={el}></span>;
  };





  return (
    <>

      <Helmet>
        <meta name="theme-color" content="#3CAA9F" />
        <link rel="canonical" href="https://www.duodots.in/website-design" />
        <title>Best Website Designing Service Agency In India - Duodots</title>
      </Helmet>

      <div className="hero website-design">
        <div className="hero_content">
          <h4>Duodots – India's Best Website Design Agency</h4>
          <h1>
            India's Best <br />
            <HeroHeading /><br />
            Service Provider.
          </h1>
          <p>
            Crafting digital excellence with trusted website design.
          </p>
          <a href="#contactForm">Get Started</a>

        </div>
        <div className="hero_image">
          <img src={heroimg} />
        </div>
      </div>

      <div className="section-padding">
        <div className="section_title">
          <h1>Duodots: Tailored Solutions for Every Business</h1>
          <p>
            Whether you're a burgeoning startup or an established enterprise, our team of seasoned designers and developers is dedicated to tailoring bespoke solutions that align with your unique business goals and objectives.
          </p>
        </div>

        <div className="home-features">
          <div className="feature" style={{ '--color': '37, 133, 207' }}>
            <i className="fi fi-sr-lightbulb-on"></i>
            <h1>Innovative Concepts</h1>
            <p>
              Fresh and inventive ideas to set your brand apart from the competition.
            </p>
          </div>

          <div className="feature" style={{ '--color': '241, 78, 28' }}>
            <i className="fi fi-sr-crown"></i>
            <h1>Seamless Branding</h1>
            <p>
              Consistent design elements across all platforms for a cohesive brand image.
            </p>
          </div>

          <div className="feature" style={{ '--color': '254, 154, 0' }}>
            <i className="fi fi-sr-time-forward"></i>
            <h1>Timely Delivery</h1>
            <p>
              Efficient turnaround times without compromising on quality.
            </p>
          </div>
        </div>


        <div className="section_title">
          <h1>Your Brand's Digital Presence with Duodots: Professional Website Design Services</h1>
          <p>
            Duodots, we specialize in empowering businesses with captivating and functional online platforms. Our professional website design services are tailored to amplify your brand's digital presence, ensuring it stands out in the competitive online landscape.
          </p>
        </div>

        <div className="service_row">

          <div className="service_box">
            <img src={responsive} />
            <h4>Responsive Website Design</h4>
            <p>
              Crafting websites optimized for all devices to ensure seamless user experiences and increased engagement.
            </p>
          </div>

          <div className="service_box">
            <img src={ecom} />
            <h4>E-commerce Website Development</h4>
            <p>
              Building robust online stores with secure payment gateways, product management systems, and seamless checkout processes.
            </p>
          </div>

          <div className="service_box">
            <img src={seo} />
            <h4>Search Engine Optimization</h4>
            <p>
              Implementing strategies to improve website visibility, organic traffic, and search engine rankings for increased online presence.
            </p>
          </div>

          <div className="service_box">
            <img src={cms} />
            <h4>Content Management Systems</h4>
            <p>
              Implementing user-friendly CMS platforms like WordPress or Joomla for easy content creation, editing, and publishing.
            </p>
          </div>

          <div className="service_box">
            <img src={hosting} />
            <h4>Web Hosting Services</h4>
            <p>
              Providing reliable hosting solutions with high uptime, fast loading speeds, and robust security measures for optimal website performance.
            </p>
          </div>

          <div className="service_box">
            <img src={wp} />
            <h4>WordPress Development Services</h4>
            <p>
              Leveraging the power of WordPress to create highly customizable, user-friendly websites tailored to your specific business needs and goals.
            </p>
          </div>




        </div>







      </div>

      <div className="contact website-design" id='contactForm'>
        <div className="section_title">
          <h1>
            The Only Website Designing Company You Need – Contact Us for Expert
            Solutions
          </h1>
          <p>Tell us your website needs, and we’ll bring your vision to life.</p>
        </div>

        <div className="contact_row">
          <div className="contact_details">
            <p>
              If you have any questions, inquiries, or require assistance, please
              don’t hesitate to reach out.
            </p>

            <p>Benefits of Choosing Our Website Designing Service:</p>

            <ul>
              <li>Professional Design</li>
              <li>Secure & high performance</li>
              <li>Scalablity</li>
              <li>Reliable Support</li>
            </ul>

            <p>Contact us:</p>

            <div className="contact_div">
              <img src={whatsapp} />
              <div>
                <h4>Whatsapp:</h4>
                <a href="https://wa.me/919821574580" target='_blank'>+91 9821574580</a>
              </div>
            </div>
            <div className="contact_div">
              <img src={emailIcon} />
              <div>
                <h4>Mail us:</h4>
                <a href="mailto:duodots.web@gmail.com">duodots.web@gmail.com</a>
              </div>
            </div>
          </div>

          <form
            action="https://duodots.in/service-email.php"
            method="post"
            className="contact_form"
            onSubmit={(event) => handleSubmit(event)}
          >
            <label for="name">Your Full Name</label>
            <input type="text" name="name" id="name" value={name} onChange={(event) =>handleChange(event)} required />

            <label for="email">Email Address</label>
            <input type="email" name="email" id="email" value={email} onChange={(event) =>handleChange(event)} required />

            <label for="phone">Phone Number</label>
            <input type="tel" name="phone" id="phone" value={phone} onChange={(event) =>handleChange(event)} required />

            <label for="service">Choose Service :</label>
            <select name="service" id="service" value={service} onChange={(event) =>handleChange(event)}>
              <option value="not selected" selected disabled>Choose</option>
              <option value="Website Design">Website Design</option>
              <option value="Wordpress Development">Wordpress Development</option>
              <option value="Ecommerce Website">Ecommerce Website</option>
              <option value="SEO">Search Engine Optimization</option>
              <option value="Other Service">Other Service</option>
            </select>

            <label for="message">Message</label>
            <textarea
              name="message"
              id="message"
              cols="30" 
              rows="5"
              value={message} 
              onChange={(event) =>handleChange(event)}
              placeholder="Tell us about your service needs so we can assist you effectively."
            ></textarea>

            
<button type="submit" disabled={isLoading}>
                                {isLoading ? 'Sending...' : 'Submit'}
                            </button>
                        {result}
          </form>
        </div>
      </div>
    </>
  )
}

export default Website
