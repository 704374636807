import React, { useState } from 'react'
import whatsapp from '../../images/icon/whatsapp.png';
import emailIcon from '../../images/icon/email.png';
import { Helmet } from 'react-helmet';
import $ from "jquery";


const Contact = () => {

  const schema = {
    "@context": "https://schema.org",
    "@type": "ContactPage",
    "name": "Contact Us",
    "description": "For questions, project discussions, or online business assistance. We’re here to help you succeed online!",
    "breadcrumb": "https://duodots.in/contact"
  };



  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'name') {
      setName(value);
    } else if (name === 'phone') {
      setPhone(value);
    }
    else if (name === 'subject') {
      setSubject(value);
    } else if (name === 'message') {
      setMessage(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: {
        email: email,
        name: name,
        phone: phone,
        subject: subject,
        message: message,
      },
      success(data) {
        if (data == 200) {
          setResult(<div className="success-msg">
            <i className="fi fi-sr-info"></i>
            <p>Your message has been sent.</p>
          </div>);
          setIsLoading(false);
          setEmail(""); // Clear email input
          setName(""); // Clear name input
          setPhone(""); // Clear name input
          setSubject(""); // Clear name input
          setMessage(""); // Clear message input
        } else {
          setResult(<div className="success-msg error">
            <i className="fi fi-sr-cross-circle"></i>
            <p>Oops an error occurred.</p>
          </div>);
          setIsLoading(false);
        }
      },
    });
  };


  return (
    <>
      <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
        <link rel="canonical" href="https://www.duodots.in/contact" />
        <title>Contact with Duodots - India's Best Website Graphic Service Agency</title>
      </Helmet>

      <div className="contact contact_page">


        <div className="contact_row">
          <div className="contact_details">
            <h1>Contact us</h1>
            <p>
              Thank you for your interest in DuoDots We’re eager to assist you in taking your business online. Feel free to reach out with any inquiries or requests for assistance.
            </p>

            <p>
              <b>Services:</b>
            </p>

            <ul>
              <li> Website Design</li>
              <li>Graphic Design </li>
            </ul>

            <p>
              <b>
                Get in Touch:
              </b>
            </p>

            <p>
              For questions, project discussions, or online business assistance. We’re here to help you succeed online!
            </p>

            <div className="contact_div">
              <img src={whatsapp} />
              <div>
                <h4>Whatsapp:</h4>
                <a href="https://wa.me/919821574580" target='_blank'>+91 9821574580</a> <br />
                <a href="https://wa.me/917042283396" target='_blank'>+91 7042283396</a>
              </div>
            </div>
            <div className="contact_div">
              <img src={emailIcon} />
              <div>
                <h4>Mail us:</h4>
                <a href="mailto:duodots.web@gmail.com">duodots.web@gmail.com</a>
              </div> 
            </div>
          </div>

          <form
            action="https://duodots.in/contact-email.php"
            method="post"
            className="contact_form"
            onSubmit={(event) => handleSubmit(event)}
          >
            <input type="text" name="name" id="name" value={name}
              onChange={(event) =>
                handleChange(event)
              } placeholder="Your Name :" required />

            <input type="email" name="email" value={email}
              onChange={(event) =>
                handleChange(event)
              } id="email" placeholder="Email Address :" required />

            <input type="tel" name="phone" value={phone}
              onChange={(event) =>
                handleChange(event)
              } id="phone" placeholder="Phone Number :" required />

            <input type="text" name="subject" value={subject}
              onChange={(event) =>
                handleChange(event)
              } id="subject" placeholder="Subject :" required />

            <textarea
              name="message"
              id="message"
              cols="30"
              rows="5"
              value={message}
              onChange={(event) =>
                handleChange(event)
              }
              placeholder="Your Message :"
            ></textarea>

            <button type="submit" disabled={isLoading}>
              {isLoading ? 'Sending...' : 'Submit'}
            </button>
            {result}

          </form>
        </div>
      </div>
    </>
  )
}

export default Contact
