import React, { useState, useEffect } from 'react';
import mainlogo from '../images/logo.png';
import logoWebsite from '../images/duodots-website.png';
import logoGraphic from '../images/duodots-graphic.png';
import { Link } from 'react-router-dom';

const Header = () => {



  const toggleElement = (selector) => {
    document.querySelector(selector).classList.toggle('active');
  };



  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    };


    // Add event listener to window for scroll events
    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  const [currentPage, setCurrentPage] = useState('home');
  const [isActive, setIsActive] = useState(false);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const getLogoAndColor = () => {
    let logo, color;

    if (currentPage === 'home') {
      logo = mainlogo;
      color = '111, 21, 187'; // Example hex color for home
    } else if (currentPage === 'graphic') {
      logo = logoGraphic;
      color = '255, 128, 31'; // Example hex color for about
    } else if (currentPage === 'website') {
      logo = logoWebsite;
      color = '60, 170, 159'; // Example hex color for contact
    } else if (currentPage === 'page') {
      logo = mainlogo;
      color = '111, 21, 187'; // Example hex color for home
    }

    return { logo, color };
  };

  let links = document.querySelectorAll('.navbar ul li');
  let menu = document.querySelector('.navbar');

  for (let i = 0; i < links.length; i++) {
    links[i].onclick = function () {
      menu.classList.remove("active");
    }
  }
  // const { logo, color } = getLogoAndColor();

  // Set default logo and color for index page
  const defaultLogo = mainlogo;
  const defaultColor = '111, 21, 187';

  const { logo, color } = currentPage === 'home' ? { logo: defaultLogo, color: defaultColor } : getLogoAndColor();



  return (
    <>
      <div style={{ '--i': color }} className={`header ${isHeaderFixed ? 'fixed' : ''}`} >
        <Link to="/" className="logo" onClick={() => handlePageChange('home')}>
          <img src={logo} alt="Duodots" />
        </Link>

        <div className={`navbar ${isActive ? 'active' : ''}`}>
          <ul>
            <li><Link to="/" onClick={() => handlePageChange('home')}>Home</Link></li>
            <li><Link to="/website-design" onClick={() => handlePageChange('website')}>Website Design</Link></li>
            <li><Link to="/graphic-design" onClick={() => handlePageChange('graphic')}>Graphic Designing</Link></li>
            <li><Link to="/faq" onClick={() => handlePageChange('page')}>FAQ's</Link></li>
            <li><Link to="/contact" onClick={() => handlePageChange('page')}>Contact</Link></li>


          </ul>
        </div>

        <div className="right-class">
          <div className="right-menu" onClick={() => toggleElement('.call-dropdown')}>
            <i className="fi fi-sr-phone-call" style={{ '--i': color }}></i>
            <div className="call-text">
              <h4>Get in Touch!</h4>
              <span>Call Now</span>
            </div>

            <ul className="call-dropdown">
              <li>
                <a href="tel:+917042283396" style={{ '--color': '255, 81, 103' }}>
                  <i className="fi fi-sr-layers"></i>
                  <div>
                    <h4>Graphic Design</h4>
                    <p>~Shadab</p>
                  </div>
                </a>
              </li>
              <li>
                <a href="tel:+919821574580" style={{ '--color': '0, 209, 153' }}>
                  <i className="fi fi-sr-site-alt"></i>
                  <div>
                    <h4>Website Design</h4>
                    <p>~Firoz</p>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className="menu" onClick={() => toggleElement('.navbar')}>
            <i className="fi fi-sr-menu-burger"></i>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
