import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Faq = () => {

  const schema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What platforms do you specialize in for website development?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We specialize in website development on popular platforms, including Wix, WordPress, Dukaan, Shopify, and Squarespace. We can help you choose the right platform based on your specific needs."
      }
    }, {
      "@type": "Question",
      "name": "How can a custom website benefit my business?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Custom website ensures that your brand’s unique identity is accurately represented online. It allows for tailored functionality, better user experiences, and improved SEO, all of which can lead to increased engagement and conversions."
      }
    }, {
      "@type": "Question",
      "name": "Can you redesign an existing website on one of these platforms?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, we offer website redesign services. We can revamp and modernize your existing website on any of the platforms we specialize in to improve its performance, design, and functionality."
      }
    },
    {
      "@type": "Question",
      "name": "Can you integrate e-commerce functionality into my website?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Absolutely. We have extensive experience in creating e-commerce websites on platforms like Shopify and others. We’ll tailor the solution to meet your specific needs."
      }
    }
  ]
  }

  useEffect(() => {
    const handleAccordionClick = (event) => {
      const accordionItem = event.target.closest('.accordion-item');
      if (!accordionItem) return;

      const accordionItems = document.querySelectorAll('.accordion-item');

      accordionItems.forEach(otherItem => {
        if (otherItem !== accordionItem && otherItem.classList.contains('active')) {
          otherItem.classList.remove('active');
        }
      });

      accordionItem.classList.toggle('active');
    };

    document.addEventListener('click', handleAccordionClick);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener('click', handleAccordionClick);
    };
  }, []);

  return (
    <>

      <Helmet>
      <link rel="canonical" href="https://www.duodots.in/faq" />
        <title>Faqs - Duoodots India's Best Website Graphic Service Agency</title>
        <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
      </Helmet>

      <div className="section_padding">

        <div className="section_title">
          <h1>Frequently asked questions</h1>
        </div>


        <div className="accordion">
          <div className="accordion-item">
            <div className="accordion-header">
              <h1>1. What platforms do you specialize in for website development?</h1>  <i className="fi fi-sr-plus-small"></i>
            </div>
            <div className="accordion-content"> <p>
              We specialize in website development on popular platforms, including Wix, WordPress, Dukaan, Shopify, and Squarespace. We can help you choose the right platform based on your specific needs.
            </p></div>
          </div>

          <div className="accordion-item">
            <div className="accordion-header">
              <h1>2. How can a custom website benefit my business?</h1>  <i className="fi fi-sr-plus-small"></i>
            </div>
            <div className="accordion-content"> <p>
              A custom website ensures that your brand’s unique identity is accurately represented online. It allows for tailored functionality, better user experiences, and improved SEO, all of which can lead to increased engagement and conversions.
            </p></div>
          </div>

          <div className="accordion-item">
            <div className="accordion-header">
              <h1>3. Can you redesign an existing website on one of these platforms?</h1>  <i className="fi fi-sr-plus-small"></i>
            </div>
            <div className="accordion-content"> <p>
              Yes, we offer website redesign services. We can revamp and modernize your existing website on any of the platforms we specialize in to improve its performance, design, and functionality.
            </p></div>
          </div>

          <div className="accordion-item">
            <div className="accordion-header">
              <h1>4. How long does it take to build a website with your service?</h1>  <i className="fi fi-sr-plus-small"></i>
            </div>
            <div className="accordion-content"> <p>
              The timeline for website development varies depending on the complexity and scope of your project. We will provide you with a detailed project timeline during the initial consultation phase.
            </p></div>
          </div>

          <div className="accordion-item">
            <div className="accordion-header">
              <h1>5. What is the cost of your website development services?</h1>  <i className="fi fi-sr-plus-small"></i>
            </div>
            <div className="accordion-content"> <p>
              The cost of our services depends on factors such as the platform chosen, the complexity of the project, and specific client requirements. We offer transparent pricing, and we’ll provide you with a customized quote after discussing your needs.
            </p></div>
          </div>

          <div className="accordion-item">
            <div className="accordion-header">
              <h1>6. Do you provide ongoing maintenance and support for websites you develop?</h1>  <i className="fi fi-sr-plus-small"></i>
            </div>
            <div className="accordion-content"> <p>
              Yes, we offer ongoing maintenance and support to ensure your website remains secure, up-to-date, and fully functional. We are committed to your long-term success.
            </p></div>
          </div>

          <div className="accordion-item">
            <div className="accordion-header">
              <h1>7. Can you integrate e-commerce functionality into my website?</h1>  <i className="fi fi-sr-plus-small"></i>
            </div>
            <div className="accordion-content"> <p>
              Absolutely. We have extensive experience in creating e-commerce websites on platforms like Shopify and others. We’ll tailor the solution to meet your specific needs.
            </p></div>
          </div>

          <div className="accordion-item">
            <div className="accordion-header">
              <h1>8. Do you handle content creation for websites?</h1>  <i className="fi fi-sr-plus-small"></i>
            </div>
            <div className="accordion-content"> <p>
              While our primary focus is website development, we can assist with content strategy and offer guidance. If you need content creation services, feel free to get in touch!
            </p></div>
          </div>

          <div className="accordion-item">
            <div className="accordion-header">
              <h1>9. Can you assist with website hosting and domain registration?</h1>  <i className="fi fi-sr-plus-small"></i>
            </div>
            <div className="accordion-content"> <p>
              Yes, we can provide guidance on hosting options and assist with domain registration. We ensure your website is set up and running smoothly.
            </p></div>
          </div>

          <div className="accordion-item">
            <div className="accordion-header">
              <h1>10. What sets Duodots apart from other website development services?</h1>  <i className="fi fi-sr-plus-small"></i>
            </div>
            <div className="accordion-content"> <p>
              We stand out due to our platform expertise, commitment to quality design, and one-month free support, ensuring your long-term satisfaction.
            </p></div>
          </div>

        </div>





      </div>
    </>
  )
}

export default Faq
